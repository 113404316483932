<template>
  <section class="feedback">
    <div class="feedback__container container">
      <div class="feedback__body">
        <div class="feedback__top">
          <h2 class="feedback__title subtitle">
            Оцените, сколько можно заработать
          </h2>
          <div class="feedback__row">
            <input
              placeholder="Введите количество гостей в месяц"
              type="text"
              v-model="guests"
              @input="handleInput"
              class="feedback__input input"
              :class="{ _error: isError }"
            />
            <a
              href="#"
              data-goto=".form"
              class="intro__button button button_second anchor-link"
            >
              Оставить заявку
            </a>
          </div>
        </div>
        <div class="feedback__content">
          <div
            v-for="item in feedbackItems"
            :key="item.id"
            class="feedback__item"
            @click="calculateProfit(item)"
            :class="{ _active: isActive(item.id) }"
          >
            <div
              class="feedback__label"
              :class="{ _active: isActive(item.id) }"
            >
              {{ profit }} рублей
            </div>
            <span></span>
            <div class="feedback-item__title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
export default {
  name: "feedback",
  components: {},
  props: {},
  setup() {
    const guests = ref();
    const profit = ref();
    const calculated = ref(true);
    const selectedItem = ref();
    const feedbackItems = [
      {
        id: 1,
        title: "в первый месяц",
      },
      {
        id: 2,
        title: "через 2 месяца",
      },
      {
        id: 4,
        title: "через 4 месяца",
      },
      {
        id: 6,
        title: "через полгода",
      },
    ];

    const activeIndex = ref(null);

    const isError = ref(false);

    const calculateProfit = (item) => {
      isError.value = false;

      let basePrice = item.id * 100;

      if (guests.value && guests.value.length > 0) {
        calculated.value = false;

        setTimeout(() => {
          profit.value = guests.value * 0.6 * basePrice;
          profit.value = Intl.NumberFormat("ru-RU").format(
            parseFloat(profit.value)
          );
        }, 300);

        if (activeIndex.value !== null) {
          activeIndex.value = null;
        }

        activeIndex.value = item.id;
      } else {
        isError.value = true;
        activeIndex.value = null;
      }
    };

    const handleInput = () => {
      guests.value = guests.value.replace(/\D/g, "");

      if (calculated.value === true) {
        isError.value = false;

        let basePrice = 1 * 100;

        setTimeout(() => {
          profit.value = guests.value * 0.6 * basePrice;
          profit.value = Intl.NumberFormat("ru-RU").format(
            parseFloat(profit.value)
          );
        }, 300);

        if (activeIndex.value !== null) {
          activeIndex.value = null;
        }

        activeIndex.value = 1;
      }
    };

    const isActive = (index) => {
      return index === activeIndex.value;
    };

    return {
      guests,
      profit,
      feedbackItems,
      calculateProfit,
      activeIndex,
      isActive,
      isError,
      handleInput,
      calculated,
      selectedItem,
    };
  },
};
</script>

<style scoped lang="scss">
.feedback {
  padding: 40px 0px 120px 0px;
  color: #fff;
  background: linear-gradient(180deg, #6cb1ff 0.43%, #c7e1ff 121.52%);

  @media (max-width: 1400px) {
    padding: 40px 0px 80px 0px;
  }

  @media (max-width: 1024px) {
    padding: 40px 0px;
  }
  &__container {
  }

  &__body {
  }

  &__row {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    align-items: start;
  }

  &__top {
    margin-bottom: 120px;

    @media (max-width: 1440px) {
      margin-bottom: 80px;
    }

    @media (max-width: 1024px) {
      margin-bottom: 60px;
    }

    @media (max-width: 492px) {
      margin-bottom: 40px;
    }
  }

  &__title {
    color: #fff;
    margin-bottom: 34px;

    @media (max-width: 1024px) {
      margin-bottom: 30px;
    }
  }

  &__input {
    font-size: 24px;
    border-bottom: 1px solid #fff;
    padding: 12px 0px;
    max-width: 600px;
    width: 100%;
    color: #fff;
    background: transparent;
    &::placeholder {
      color: #fff;
      transition: all 0.3s ease 0s;
    }

    transition: all 0.3s ease 0s;

    &._error {
      &::placeholder {
        color: #bb2121;
      }

      border-bottom: 1px solid #bb2121;
    }

    @media (max-width: 1580px) {
      font-size: 20px;
    }

    @media (max-width: 1024px) {
      font-size: 18px;
    }

    @media (max-width: 492px) {
      font-size: 16px;
    }
  }

  &__content {
    display: flex;
    padding: 0px 60px;
    justify-content: center;
    @media (max-width: 1024px) {
      padding: 0;
    }

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  &__item {
    flex: 0 0 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    max-width: 300px;
    justify-content: start;
    @media (max-width: 600px) {
      flex-direction: row-reverse;
      gap: 8px;
    }
    span {
      display: block;
      width: 100%;
      height: 5px;
      background-color: #fff;
      margin-bottom: 32px;
      position: relative;
      align-self: stretch;
      @media (max-width: 600px) {
        width: 3px;
        height: 200px;
        margin: 0;
      }
      &:before {
        content: "";
        display: block;
        border-radius: 50%;
        transition: all 0.4s ease 0s;
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        background-color: #fff;
        border: 5px solid #fff;
        position: absolute;
        top: -20px;
        left: calc(50% - 20px);

        @media (max-width: 1580px) {
          width: 30px;
          height: 30px;
          flex: 0 0 30px;
          top: -15px;
          left: calc(50% - 15px);
        }

        @media (max-width: 600px) {
          width: 20px;
          height: 20px;
          flex: 0 0 20px;
          top: calc(50% - 10px);
          left: -10px;
          border: 3px solid #fff;
        }
      }
    }

    &._active {
      span {
        &:before {
          border: 5px solid #67aeff;

          @media (max-width: 767px) {
            border: 3px solid #67aeff;
          }
        }
      }
    }
  }

  &__label {
    font-size: 24px;
    margin-bottom: 32px;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    transition: all 0.3s ease 0s;
    @media (max-width: 1580px) {
      font-size: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 18px;
    }

    @media (max-width: 600px) {
      margin: 0 0 0 16px;
    }
    &._active {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transition: all 0.3s ease 0.3s;
    }
  }
}

.feedback-item {
  &__title {
    font-size: 24px;
    font-weight: 500;
    @media (max-width: 1580px) {
      font-size: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 18px;
    }

    @media (max-width: 600px) {
      flex: 0 0 100px;
    }
  }
}
</style>
