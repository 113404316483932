<template>
  <section class="benefits section">
    <div class="benefits__container container">
      <div class="benefits__body">
        <div class="benefits__top">
          <h2 class="benefits__title title">
            Узнайте о всех преимуществах <br />
            ландромата для вашего бизнеса
          </h2>
        </div>
        <div class="benefits__row">
          <div class="benefits__item benefits-item">
            <div class="benefits-item__title">
              Дополнительный <br />
              доход
            </div>
            <p>
              Получайте доход с каждой стирки гостя в ландромате. Мы бесплатно
              предоставляем оборудование и обслуживание.
            </p>
          </div>
          <div class="benefits__item benefits-item">
            <div class="benefits-item__title">
              Преимущество перед <br />
              конкурентами
            </div>
            <p>
              Только 36% объектов предоставляют возможность стирки. Выделяйтесь
              на рынке и привлекайте больше клиентов, предоставляя уникальный
              сервис.
            </p>
          </div>
          <div class="benefits__item benefits-item">
            <div class="benefits-item__title">
              Повышение <br />
              рейтинга отеля
            </div>
            <p>
              Помощь гостю, в ситуации когда понадобилась стирка, значительно
              улучшит рейтинг и репутацию вашего отеля.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed, inject, onMounted } from "vue";
export default {
  name: "header",
  components: {},
  props: {},
  setup() {},
};
</script>

<style scoped lang="scss">
.benefits {
  color: #fff;
  background-color: #67aeff;

  @media (max-width: 1024px) {
    padding: 24px 0px 80px 0px;
  }
  &__container {
  }

  &__body {
  }

  &__top {
    margin-bottom: 160px;

    @media (max-width: 1680px) {
      margin-bottom: 70px;
    }

    @media (max-width: 767px) {
      margin-bottom: 48px;
    }
  }

  &__title {
    font-size: 70px;
    font-weight: 500;
    color: #fff;

    @media (max-width: 1680px) {
      font-size: 50px;
    }

    @media (max-width: 1440px) {
      font-size: 40px;
    }

    @media (max-width: 1190px) {
      font-size: 32px;
    }

    @media (max-width: 767px) {
      font-size: 24px;
    }

    @media (max-width: 492px) {
      font-size: 20px;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 60px;

    @media (max-width: 1680px) {
      gap: 40px;
    }
  }

  &__item {
  }
}

.benefits-item {
  padding-bottom: 75px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  border-bottom: 1px solid #fff;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
  }
  @media (max-width: 1680px) {
    padding-bottom: 40px;
  }

  @media (max-width: 1440px) {
    padding-bottom: 24px;
  }
  &__title {
    font-size: 60px;
    font-weight: 500;

    @media (max-width: 1680px) {
      font-size: 40px;
    }

    @media (max-width: 1440px) {
      font-size: 36px;
    }

    @media (max-width: 1024px) {
      font-size: 24px;
      margin-bottom: 16px;
      font-weight: 700;
    }

    @media (max-width: 492px) {
      font-size: 20px;
    }
  }

  p {
    display: block;
    flex: 0 0 450px;
    font-size: 30px;
    font-weight: 400;

    @media (max-width: 1680px) {
      font-size: 24px;
    }

    @media (max-width: 1440px) {
      font-size: 20px;
    }

    @media (max-width: 1024px) {
      font-size: 16px;
      flex: auto;
    }
  }
}
</style>
