<template>
  <section class="products section">
    <div class="products__container container">
      <div class="products__body">
        <div class="products__row">
          <a href="#" class="products__item products-item">
            <h3 class="products-item__title">Получить ландромат для стирки</h3>
            <div class="products-item__labels">
              <div class="products-item__label">
                Стоимость: 0 <span><span class="currency"> ₽ </span></span>
              </div>
              <div class="products-item__label">Стоимость установки:</div>
              <div class="products-item__label products-item__label_center">
                <span>0 <span class="currency"> ₽ </span></span> , если ландромат стоит более 6 месяцев
              </div>
              <div class="products-item__label products-item__label_center">
                <span>15 000 <span class="currency"> ₽ </span></span> , если ландромат стоит более 6 месяцев
              </div>
              <div class="products-item__label">
                Стоимость стирки для гостя: 249<span class="currency"> ₽ </span>
              </div>
            </div>
            <div class="products-item__subtitle">
              Доход с каждой стирки: 100 <span class="currency"> ₽ </span>
            </div>
            <div class="products-item__text">
              Мы можем предоставлять средство для стирки за 25 <span class="currency"> ₽ </span>/стирка
            </div>
          </a>
          <a href="#" class="products__item products-item products-item_blue">
            <h3 class="products-item__title">Купить ландромат для стирки</h3>
            <div class="products-item__labels">
              <div class="products-item__label">Стоимость: 30 000 <span class="currency"> ₽ </span></div>
              <div class="products-item__label">Стоимость установки:</div>
              <div class="products-item__label products-item__label_center">
                <span>0 <span class="currency"> ₽ </span></span> , если ландромат стоит более 6 месяцев
              </div>
              <div class="products-item__label products-item__label_center">
                <span>15 000<span class="currency"> ₽ </span></span> , если ландромат стоит более 6 месяцев
              </div>
              <div class="products-item__label">
                Стоимость стирки для гостя: 249 <span class="currency"> ₽ </span>
              </div>
            </div>
            <div class="products-item__subtitle">
              Доход с каждой стирки: 153 <span class="currency"> ₽ </span>
            </div>
          </a>
          <a href="#" class="products__item products-item">
            <h3 class="products-item__title">Получить ландромат для сушки</h3>
            <div class="products-item__labels">
              <div class="products-item__label">
                Стоимость: 0 <span><span class="currency"> ₽ </span></span>
              </div>
              <div class="products-item__label">Стоимость установки:</div>
              <div class="products-item__label products-item__label_center">
                <span>0 <span class="currency"> ₽ </span></span> , если ландромат стоит более 6 месяцев
              </div>
              <div class="products-item__label products-item__label_center">
                <span>15 000 <span class="currency"> ₽ </span></span> , если ландромат стоит более 6 месяцев
              </div>
              <div class="products-item__label">
                Стоимость сушки для гостя: 249 <span class="currency"> ₽ </span>
              </div>
            </div>
            <div class="products-item__subtitle">
              Доход с каждой сушки: 90 <span class="currency"> ₽ </span>
            </div>
          </a>
          <a href="#" class="products__item products-item products-item_blue">
            <h3 class="products-item__title">Купить ландромат для сушки</h3>
            <div class="products-item__labels">
              <div class="products-item__label">Стоимость: 40 000 <span class="currency"> ₽ </span></div>
              <div class="products-item__label">Стоимость установки:</div>
              <div class="products-item__label products-item__label_center">
                <span>0 <span class="currency"> ₽ </span></span> , если ландромат стоит более 6 месяцев
              </div>
              <div class="products-item__label products-item__label_center">
                <span>15 000 <span class="currency"> ₽ </span></span> , если ландромат стоит более 6 месяцев
              </div>
              <div class="products-item__label">
                Стоимость стирки для гостя: 249 <span class="currency"> ₽ </span>
              </div>
            </div>
            <div class="products-item__subtitle">
              Доход с каждой сушки: 153 <span class="currency"> ₽ </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "intro",
  components: {},
  props: {},
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.products {
  padding: 80px 0;
  @media (max-width: 1024px) {
    padding: 40px 0px;
  }
  &__container {
    max-width: 1030px;

    @media(max-width:1580px) {
      max-width: 1110px;
    }

    @media(max-width:1200px) {
      max-width: 100%;
    }
  }

  &__body {
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 48px 24px;

    @media(max-width:767px) {
        gap: 20px;
        grid-template-columns: repeat(1,1fr );
    }
  }

  &__item {
  }
}

.products-item {
  border: 5px solid #6eb2ff;
    border-radius: 40px;
  transition: all 0.4s ease 0s;
    display: flex;
    flex-direction: column;
  &:hover {
    transform: scale(1.01);
  }
  padding: 48px 32px;
  color: #4a9fff;

  @media(max-width:1580px) {
    padding: 32px 24px;
    border-radius: 24px;
     border: 3px solid #6eb2ff;
  }

  @media(max-width:767px) {
    padding: 28px 22px;
    border-radius: 20px;
  }
  &_blue {
    background: linear-gradient(141deg, #6eb2ff 27.21%, #b6d9ff 92.28%);
    color: #fff;
    border: 5px solid #6eb2ff;

    @media(max-width:1580px) {
          border: 3px solid #6eb2ff;
    }
  }

  &__title {
    font-size: 48px;
    margin-bottom: 50px;
    font-weight: 500;

    @media(max-width:1580px) {
        font-size: 42px;
    }

    @media(max-width:767px) {
        font-size: 32px;
        margin-bottom: 24px;
    }

    @media(max-width:492px) {
        font-size: 24px;
    }
  }

  &__labels {
    margin-bottom: 10px;
    flex: 1 1 100%;
  }

  &__label {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    @media(max-width:1580px) {
        font-size: 20px;
    }
    span {
      font-weight: 700;
    }
    span.currency {
      font-weight: 500;
    }
    &_center {
      padding-left: 40px;
      @media(max-width:767px) {
        padding-left: 20px;
      }
    }

      @media(max-width:767px) {
        font-size: 16px;
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 32px;

    @media(max-width:1580px) {
        font-size: 28px;
    }

    @media(max-width:1024px) {
        font-size: 24px;
    }
  }

  &__text {
    font-size: 24px;
    font-weight: 500;
    margin-top: 24px;
    @media(max-width:1580px) {
        font-size: 20px;
    }

    @media(max-width:767px) {
        font-size: 16px;
    }
  }

  
}

.currency {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 500;
}
</style>
