<template>
  <section class="questions section">
    <div class="questions__container container">
      <div class="questions__body">
        <div class="questions__top">
          <h2 class="questions__title subtitle">Часто задаваемые вопросы</h2>
        </div>
        <div class="questions__content" data-spollers data-one-spoller>
          <div class="questions__item questions-item">
            <button data-spoller class="questions-item__title">
              Сколько будет стоить стирка и сушка вещей хостела?
            </button>
            <div class="questions-item__content">50 рублей</div>
          </div>
          <div class="questions__item questions-item">
            <button data-spoller class="questions-item__title">
              Какая гарантия на ландромат при покупке?
            </button>
            <div class="questions-item__content">
              1 год (2000 циклов сушки и стирки)
            </div>
          </div>
          <div class="questions__item questions-item">
            <button data-spoller class="questions-item__title">
              Что входит в функционал приложения?
            </button>
            <div class="questions-item__content">
              В функционал входит возможность бронирования, оповещения о
              состоянии сушки, выбор конфигурации сушки (время, температура и
              др.), оплата и поддержка от prachka.com
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted } from "vue";
import { useSpollers } from "../helpers/spollers.js";
export default {
  name: "questions",
  components: {},
  props: {},
  setup() {
    // useSpollers()
    const initSpollers = () => {
      const spollersArray = document.querySelectorAll("[data-spollers]");

      //получение обычных спойлеров
      const spollersRegular = Array.from(spollersArray).filter(function (
        item,
        index,
        self
      ) {
        return !item.dataset.spollers.split(",")[0];
      });
      //инициализация спойлера
      if (spollersRegular.length > 0) {
        initSpollers(spollersRegular);
      }
      //получение спойлеров с медиа запросами
      const spollersMedia = Array.from(spollersArray).filter(function (
        item,
        index,
        self
      ) {
        return item.dataset.spollers.split(",")[0];
      });

      // инициализация спойлеров с медиа запросами
      if (spollersMedia.length > 0) {
        const breakpointsArray = [];
        spollersMedia.forEach((item) => {
          const params = item.dataset.spollers;
          const breakpoint = {};
          const paramsArray = params.split(",");
          breakpoint.value = paramsArray[0];
          breakpoint.type = paramsArray[1] ? paramsArray[1].trim() : "max";
          breakpoint.item = item;
          breakpointsArray.push(breakpoint);
        });

        //получение уникальных брейкпоинтов
        let mediaQueries = breakpointsArray.map(function (item) {
          return (
            "(" +
            item.type +
            "-width:" +
            item.value +
            "px)," +
            item.value +
            "," +
            item.type
          );
        });
        mediaQueries = mediaQueries.filter(function (item, index, self) {
          return self.indexOf(item) === index;
        });

        //работа с каждым брейкпоинтом
        mediaQueries.forEach((breakpoint) => {
          const paramsArray = breakpoint.split(",");
          const mediaBreakpoint = paramsArray[1];
          const mediaType = paramsArray[2];
          const matchMedia = window.matchMedia(paramsArray[0]);

          //обьекты с нужными значениями
          const spollersArray = breakpointsArray.filter(function (item) {
            if (item.value === mediaBreakpoint && item.type === mediaType) {
              return true;
            }
          });
          //событие
          matchMedia.addListener(function () {
            initSpollers(spollersArray, matchMedia);
          });
          initSpollers(spollersArray, matchMedia);
        });
      }
      //инициализация
      function initSpollers(spollersArray, matchMedia = false) {
        spollersArray.forEach((spollersBlock) => {
          spollersBlock = matchMedia ? spollersBlock.item : spollersBlock;
          if (matchMedia.matches || !matchMedia) {
            spollersBlock.classList.add("_init");
            initSpollerBody(spollersBlock);
            spollersBlock.addEventListener("click", setSpollerAction);
          } else {
            spollersBlock.classList.remove("_init");
            initSpollerBody(spollersBlock, false);
            spollersBlock.removeEventListener("click", setSpollerAction);
          }
        });
      }

      function initSpollerBody(spollersBlock, hideSpollerBody = true) {
        const spollerTitles = spollersBlock.querySelectorAll("[data-spoller]");
        if (spollerTitles.length > 0) {
          spollerTitles.forEach((spollerTitle) => {
            if (hideSpollerBody) {
              spollerTitle.removeAttribute("tabindex");
              if (!spollerTitle.classList.contains("_active")) {
                spollerTitle.nextElementSibling.hidden = true;
              }
            } else {
              spollerTitle.setAttribute("tabindex", "-1");
              spollerTitle.nextElementSibling.hidden = false;
            }
          });
        }
      }

      function setSpollerAction(e) {
        const el = e.target;
        if (el.hasAttribute("data-spoller") || el.closest("[data-spoller]")) {
          const spollerTitle = el.hasAttribute("data-spoller")
            ? el
            : el.closest("[data-spoller]");
          const spollersBlock = spollerTitle.closest("[data-spollers]");
          const oneSpoller = spollersBlock.hasAttribute("data-one-spoller")
            ? true
            : false;
          if (!spollersBlock.querySelectorAll("._slide").length) {
            if (oneSpoller && !spollerTitle.classList.contains("_active")) {
              hideSpollersBody(spollersBlock);
            }
            spollerTitle.classList.toggle("_active");
            _slideToggle(spollerTitle.nextElementSibling, 500);
          }
          e.preventDefault();
        }
      }

      function hideSpollersBody(spollersBlock) {
        const spollerActiveTitle = spollersBlock.querySelector(
          "[data-spoller]._active"
        );
        if (spollerActiveTitle) {
          spollerActiveTitle.classList.remove("_active");
          _slideUp(spollerActiveTitle.nextElementSibling, 500);
        }
      }

      //================================================================================================

      //SlideToggle
      let _slideUp = (target, duration = 500) => {
        if (!target.classList.contains("_slide")) {
          target.classList.add("_slide");
          target.style.transitionProperty = "height, margin, padding";
          target.style.transitionDuration = duration + "ms";
          target.style.height = target.offsetHeight + "px";
          target.offsetHeight;
          target.style.overflow = "hidden";
          target.style.height = 0;
          target.style.paddingTop = 0;
          target.style.paddingBottom = 0;
          target.style.marginTop = 0;
          target.style.marginBottom = 0;
          window.setTimeout(() => {
            target.hidden = true;
            target.style.removeProperty("height");
            target.style.removeProperty("padding-top");
            target.style.removeProperty("padding-bottom");
            target.style.removeProperty("mragin-top");
            target.style.removeProperty("margin-bottom");
            target.style.removeProperty("overflow");
            target.style.removeProperty("transition-duration");
            target.style.removeProperty("transition-property");
            target.classList.remove("_slide");
          }, duration);
        }
      };

      let _slideDown = (target, duration = 500) => {
        if (!target.classList.contains("_slide")) {
          target.classList.add("_slide");
          if (target.hidden) {
            target.hidden = false;
          }
          let height = target.offsetHeight;
          target.style.overflow = "hidden";
          target.style.height = 0;
          target.style.paddingTop = 0;
          target.style.paddingBottom = 0;
          target.style.marginTop = 0;
          target.style.marginBottom = 0;
          target.offsetHeight;
          target.style.transitionProperty = "height, margin, padding";
          target.style.transitionDuration = duration + "ms";
          target.style.height = height + "px";
          target.style.removeProperty("padding-top");
          target.style.removeProperty("padding-bottom");
          target.style.removeProperty("margin-top");
          target.style.removeProperty("margin-bottom");
          window.setTimeout(() => {
            target.style.removeProperty("height");
            target.style.removeProperty("overflow");
            target.style.removeProperty("transition-duration");
            target.style.removeProperty("transition-property");
            target.classList.remove("_slide");
          }, duration);
        }
      };

      let _slideToggle = (target, duration = 500) => {
        if (target.hidden) {
          return _slideDown(target, duration);
        } else {
          return _slideUp(target, duration);
        }
      };
    };

    onMounted(initSpollers);

  },
};
</script>

<style scoped lang="scss">
.questions {
  padding: 80px 0px;
  @media (max-width: 1024px) {
    padding: 40px 0px;
  }
  &__container {
  }

  &__body {
  }

  &__top {
    margin-bottom: 60px;
    @media (max-width: 1024px) {
      margin-bottom: 40px;
    }
  }

  &__title {
    color: #67AEFF;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__item {
  }
}

.questions-item {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding-bottom: 32px;
    color: #6eb2ff;
    font-weight: 500;
    font-size: 48px;
    @media (max-width: 1580px) {
      font-size: 40px;
    }
    text-align: left;
    @media (max-width: 1200px) {
      font-size: 32px;
    }

    @media (max-width: 1024px) {
      font-size: 24px;
      gap: 16px;
    }

    &:after {
      content: "";
      display: block;
      background-image: url(../../assets/img/arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 60px;
      height: 30px;
      flex: 0 0 60px;
      transition: all 0.4s ease 0s;

      @media(max-width:1440px) {
        width: 40px;
        height: 20px;
        flex: 0 0 40px;
      }

      @media(max-width:1024px) {
        width: 30px;
        height: 15px;
        flex: 0 0 30px;
      }
    }

    &._active {
      &:after {
        transform: rotate(90deg);
      }
    }
  }

  &__content {
    max-width: 1100px;
    font-size: 24px;
    color: #6eb2ff;

    @media (max-width: 1400px) {
      font-size: 20px;
    }

    @media (max-width: 1024px) {
      font-size: 18px;
    }
  }
}
</style>
