<template>
  <section class="intro">
    <div class="intro__container container">
      <div class="intro__body">
        <div class="intro__label">
          Установи ландромат <a href="https://prachka.com">prachka.com</a>
        </div>
        <h1 class="intro__title title">
          Пассивный доход <br />
          отелю, удобная стирка <br />
          и сушка гостям
        </h1>
        <div class="intro__buttons">
          <a href="#" data-goto=".form" class="intro__button button button_highlight anchor-link">
            Оставить заявку
          </a>
          <a href="#" data-goto=".feedback" class="intro__button button anchor-link"> Рассчитать доход </a>
        </div>
      </div>
    </div>
  </section>
  
</template>

<script>
export default {
  name: "intro",
  components: {},
  props: {},
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.intro {

  &__container {
  }

  &__label {
    font-size: 36px;
    color: #2A8BFF;
    a {
      display: inline-block;
      color: #2A8BFF;
      font-weight: 500;
      transition: all 0.4s ease 0s;
      &:hover {
        opacity: 0.8;
      }
    }

    @media (max-width: 1580px) {
      font-size: 36px;
    }

    @media (max-width: 1300px) {
      font-size: 28px;
    }

    @media (max-width: 1024px) {
      font-size: 24px;
    }

    @media(max-width:492px) {
      font-size: 18px;
      text-align: left;
    }
  }

  &__body {
    padding: 160px 0 80px 0;
    min-height: 100vh;
    border-bottom: 1px solid #67aeff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 48px;
    @media (max-width: 1024px) {
      padding: 120px 0px 40px 0px;
      gap: 32px;

      min-height: calc(100vh);
    }

    @media(max-width:492px) {
      align-items: stretch;
      gap: 16px;
      padding: 120px 0px 60px 0px;
       border: none;
    }
  }

  &__title {
    margin-bottom: 20px;
    @media(max-width:1024px) {
      flex: 1 1 100%;
    }

    @media(max-width:492px) {
      flex: auto;
    }
  }

  &__buttons {
    display: flex;
    gap: 24px;

    @media(max-width:492px) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__button {
    border-radius: 20px;
    font-size: 20px;
    line-height: 120%;
    padding: 24px 40px;

    @media (max-width: 1300px) {
      font-size: 18px;
      padding: 20px 32px;
    }

    @media (max-width: 1024px) {
      font-size: 16px;
      padding: 16px 28px;
      border-radius: 8px;
    }

    @media(max-width:492px) {
      display: flex;
      width: 100%;
    }
  }
}
</style>
