<template>
  <section class="about section">
    <div class="about__container container">
      <div class="about__body">
        <div class="about__images">
          <div class="about__image">
            <img src="../../assets/img/about/main.png" alt="" />
          </div>
          <div class="about__bg">
            <img src="../../assets/img/about/bg.svg" alt="" />
          </div>
        </div>
        <div class="about__content">
          <p>
            <span>Ландромат</span> — профессиональное оборудование для
            автоматической прачечной самообслуживания. Гости
            <span>самостоятельно</span> выполняют стирку и сушку, бронируя,
            оплачивая и управляя ими <span>через приложение</span>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed, inject, onMounted } from "vue";
export default {
  name: "header",
  components: {},
  props: {},
  setup() {},
};
</script>

<style scoped lang="scss">
.about {
  position: relative;
  overflow: hidden;

  @media(max-width:1190px) {
    padding: 60px 0;
  }
  &__container {
  }

  &__body {
    display: flex;
    justify-content: space-between;
    gap: 120px;


    @media(max-width:1440px) {
        gap: 40px;
    }

    @media(max-width:767px) {
        flex-direction: column;
        align-items: center;
    }
  }

  &__images {
  }

  &__image {
    position: relative;
    z-index: 1;
    img {
      max-width: 760px;
    }

    @media (max-width: 1640px) {
      img {
        max-width: 450px;
      }
    }
     @media(max-width:1440px) {
        img {
            left: -150px
        }
    }

    @media(max-width:1190px) {
        img {
            max-width: 350px;
            left: -100px;
        }
    }

    @media(max-width:767px) {
        img {
            max-width: 290px;
            width: 100%;
        }
    }
  }

  &__bg {
    img {
      position: absolute;
      object-fit: cover;
      top: -100px;
      left: -200px;
      max-width: 1200px;
    }

    @media (max-width: 1640px) {
      img {
        max-width: 1000px;
        left: -300px;
      }
    }

    @media(max-width:1190px) {
        img {
            max-width: 650px;
            left: -300px;
        }
    }

     @media(max-width:767px) {
        img {
            max-width: 550px;
            left: -300px;
        }
    }

   
  }

  &__content {
    max-width: 700px;
    flex: 0 1 700px;
    position: relative;
    z-index: 1;
    color: #3694ff;
    font-size: 30px;
    span {
      font-weight: 500;
    }

    @media(max-width:1440px) {
        font-size: 24px;
    }

    @media(max-width:1024px) {
        font-size: 20px;
    }

    @media(max-width:767px) {
        font-size: 16px;
        flex: auto;
    }
  }
}
</style>
