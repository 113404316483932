<template>
  <div class="wrapper">
    <Header></Header>
    <main>
      <Intro></Intro>
      <about></about>
      <benefits></benefits>
      <works></works>
      <advantages></advantages>
      <feedback></feedback>
      <business-solutions></business-solutions>
      <products></products>
      <text-section></text-section>
      <div class="form section">
        <div class="form__container container" style="width: 100%;">
          <h2 class="form__title subtitle">
            Оставить заявку
          </h2>
          <div class="form__item">

          </div>
        </div>
      </div>
      <questions></questions>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import { onMounted } from "vue";
import Header from "../views/components/header.vue";
import Intro from "../views/components/intro.vue";
import About from "../views/components/about.vue";
import Benefits from "../views/components/benefits.vue";
import Works from "../views/components/works.vue";
import Advantages from "../views/components/advantages.vue";
import Feedback from "../views/components/feedback.vue";
import businessSolutions from "../views/components/business-solutions.vue";
import products from "../views/components/products.vue";
import textSection from "../views/components/text-section.vue";
import questions from "../views/components/questions.vue";
import Footer from "../views/components/footer.vue";
// import AmoForms from "../views/components/AmoForms.vue";
export default {
  name: "home",
  components: {
    Header,
    Intro,
    About,
    Benefits,
    Works,
    Advantages,
    Feedback,
    businessSolutions,
    products,
    textSection,
    questions,
    Footer,
    // AmoForms
  },
  props: {},
  setup(props) {
    onMounted(() => {
      initScrollLinks();
    });

    const initScrollLinks = () => {
      const menuLinks = document.querySelectorAll(".anchor-link[data-goto]");
      menuLinks.forEach((menuLink) => {
        menuLink.addEventListener("click", onMenuLinkClick);
      });
      function onMenuLinkClick(e) {
        const menuLink = e.target;
        if (
          menuLink.dataset.goto &&
          document.querySelector(menuLink.dataset.goto)
        ) {
          const gotoBlock = document.querySelector(menuLink.dataset.goto);
          const gotoBlockValue =
            gotoBlock.getBoundingClientRect().top + pageYOffset;

          window.scrollTo({
            top: gotoBlockValue,
            behavior: "smooth",
          });
          e.preventDefault();

          if (document.querySelector(".header__icon").classList.contains('_active')) {
            document.querySelector(".header__icon").classList.remove("_active");
            document.querySelector(".header__content").classList.remove("_active");
          }
        }
      }
    };
    return {
      initScrollLinks
    };
  },
};
</script>

<style lang="scss">
.wrapper {
  overflow: hidden;

  .form {
    
    padding: 80px 0px;

    @media(max-width:767px) {
      padding: 50px 0;
    }

    &__container {
      width: 100%;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__title {
      color: #67AEFF;
      margin-bottom: 60px;

      @media(max-width:1024px) {
        margin-bottom: 40px;
      }
    }
  }
}
</style>
