<template>
  <section class="advantages section">
    <div class="advantages__container container">
      <div class="advantages__body">
        <div class="advantages__top">
          <h2 class="advantages__title subtitle">
            Зачем вам нужен ландромат
            <a href="https://prachka.com">prachka.com?</a>
          </h2>
        </div>
        <div class="advantages__row">
          <div class="advantages__item advantages-item">
            <div class="advantages-item__title">
              Легкость и удобство <br> использования
            </div>
            <div class="advantages-item__text">
              <p>
                Ваши работники не тратят время на обслуживание стиральной
                машинки. Гости получают интуитивное приложение для бронирования
                и оплаты, что делает процесс стирки простым и понятным даже для
                тех, кто впервые пользуется таким сервисом
              </p>
            </div>
          </div>
          <div class="advantages__item advantages-item">
            <div class="advantages-item__title">Экономия денег</div>
            <div class="advantages-item__text">
              <p>
                В свободные слоты отель может стирать и сушить в ландромате
                полотенца и постельное белье, что позволит экономить на внешних
                прачечных
              </p>
            </div>
          </div>
          <div class="advantages__item advantages-item">
            <div class="advantages-item__title">
              Гарантированное <br> качество стирки
            </div>
            <div class="advantages-item__text">
              <p>
                Используя ландромат, гости могут быть уверены в качестве стирки
                и сушки, не опасаясь за состояние своей одежды
              </p>
            </div>
          </div>
          <div class="advantages__item advantages-item">
            <div class="advantages-item__title">Экономия времени <br> гостей</div>
            <div class="advantages-item__text">
              <p>
                Бронирование и оплата в телефоне у вашего гостя. Благодаря
                функции бронирования не нужно ждать, когда машинка освободится.
                Никаких очередей на стирку в хостеле
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "advantages",
  components: {},
  props: {},
  setup() {},
};
</script>

<style scoped lang="scss">
.advantages {
    padding: 80px 0px;

    @media(max-width:1024px) {
        padding: 60px 0px;
    }
  &__container {
  }

  &__body {
  }

  &__top {
    margin-bottom: 60px;

    @media(max-width:1024px) {
        margin-bottom: 40px;
    }
  }

  &__title {
    color: #67AEFF;
    a {
        font-weight: 500;
        color: #67AEFF;
    }
  }

  &__row {
    padding-left: 30%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media(max-width:1024px) {
        padding: 0;
    }
  }

  &__item {
  }
}

.advantages-item {
    padding-bottom: 40px;
    border-bottom: 1px solid #67AEFF;
  &__title {
    margin-bottom: 24px;
    font-size: 44px;
    color: #67AEFF;
    font-weight: 700;

    @media(max-width:1580px) {
        font-size: 40px;
    }

    @media(max-width:1440px) {
        font-size: 32px;
    }

    @media(max-width:1024px) {
        margin-bottom: 8px;
    }

    @media(max-width:492px) {
        font-size: 24px;
    }
  }

  &__text {
    font-size: 24px;
    color: #67AEFF;
    max-width: 900px;

    @media(max-width:1024px) {
        font-size: 20px;
    }

    @media(max-width:492px) {
        font-size: 16px;
    }
  }
}
</style>
