<template>
  <section class="text-section section">
    <div class="text-section__container container">
      <div class="text-section__body">
        <h3 class="text-section__label">
          Хостел получает доступ к отчетности и свою часть дохода в форме
          агентских выплат каждые 2 недели. Финансовые взаимоотношения с
          клиентом и юридическая ответственность в рамках оказания услуги - на
          стороне <span>prachka.com.</span>
        </h3>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
export default {
  name: "header",
  components: {},
  props: {},
  setup() {},
};
</script>

<style scoped lang="scss">
.text-section {
    padding: 80px 0px;

    @media(max-width:1024px) {
        padding: 40px 0;
    }
  &__container {

    @media(max-width:492px) {
      padding: 0;
    }
  }

  &__body {
  }

  &__label {
    font-size: 48px;
    color: #6EB2FF;
    padding: 60px;
    border-radius: 40px;
    background-color: #6EB2FF;
    color: #fff;
    line-height: 130%;
    @media(max-width:1580px) {
        font-size: 40px;
        padding: 50px;
        border-radius: 28px;
    }

    @media(max-width:1024px) {
        font-size: 32px;
    }

    @media(max-width:767px) {
        font-size: 24px;
        border-radius: 16px;
        padding: 40px;
    }

    @media(max-width:492px) {
        font-size: 18px;
        padding: 20px;
        border-radius: 0;
    }
    a {
        color: #6EB2FF;
        text-decoration: underline;
        text-decoration-skip-ink: none;
    }
  }
}
.section {
}
.container {
}
</style>
