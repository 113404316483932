<template>
  <section class="works">
    <div class="works__container container">
      <div class="works__body">
        <div class="works__top">
          <h2 class="works__title subtitle">До дохода - три простых шага</h2>
        </div>
        <div class="works__content">
          <div class="works__arrow">

          </div>
          <swiper
            :modules="modules"
            :pagination="{ clickable: true }"
            :slides-per-view="'auto'"
            :space-between="30"
            navigation
            :breakpoints="{
              767: { slidesPerView: 1 },
              768: { slidesPerView: 'auto' },
            }"
          >
            <swiper-slide>
              <div class="works__item works-item">
                <div class="works-item__title subtitle">
                  Определяем место установки
                </div>
                <div class="works-item__number">1</div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="works__item works-item">
                <div class="works-item__title subtitle">
                  Устанавливаем ландроматы и подключаем их к нашей системе
                </div>
                <div class="works-item__number">2</div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="works__item works-item">
                <div class="works-item__title subtitle">
                  Гости получают удобный сервис, а вы дополнительный доход дважды в
                  месяц
                </div>
                <div class="works-item__number">3</div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

export default {
  name: "works",
  props: {},
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
};
</script>

<style lang="scss">
.works {
  &__container {
  }

  &__body {
    padding: 100px 0px 140px 0px;

    @media (max-width: 1024px) {
      padding: 60px 0px 60px 0px;
    }
    border-bottom: 1px solid #67aeff;
  }

  &__top {
    color: #67aeff;
    border-bottom: 1px solid #67aeff;
    padding-bottom: 16px;
    margin-bottom: 80px;

    @media (max-width: 1024px) {
      margin-bottom: 40px;
    }
  }

  &__title {
  }

  .swiper {
    .swiper-pagination {
      display: none;
      gap: 10px;
      padding-top: 25px;
      justify-content: center;
      span {
        transition: all 0.4s ease 0s;
        width: 20px;
        flex: 0 0 20px;
        height: 7px;
        border-radius: 20px;
        background-color: #d9d9d9;

        &.swiper-pagination-bullet-active {
          flex: 0 0 120px;
          background-color: #a4cfff;
        }
      }
    }

    @media (max-width: 767px) {
      .swiper-pagination {
        display: flex;
      }
    }
  }

  &__content {
    width: calc(50% + 50vw);

    .swiper-slide {
      width: 640px;

      @media (max-width: 1580px) {
        width: 480px;
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .swiper {
      z-index: 0;

      &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 350px;
        left: -60px;
        top: 0;
        background: linear-gradient(91deg, #FCFEFF -21.25%, rgba(252, 254, 255, 0.00) 98.86%);
        z-index: 2;

        @media(max-width:767px) {
          display: none;
        }
      }

      &:after {
        right: -60px;
        left: inherit;
        background: linear-gradient(91deg, #FCFEFF -21.25%, rgba(252, 254, 255, 0.00) 98.86%);
        transform: rotate(180deg);
      }
    }

    position: relative;
    .swiper-button-next, .swiper-button-prev {
      width: 75px;
      height: 40px;
      background-image: url(../../assets/img/arrow-2.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      top: calc(50% - 20px);
      left: 12px;
      z-index: 3;
      transform: rotate(180deg);
      cursor: pointer;
      transition: all 0.4s ease 0s;
      &:hover {
        transform: scale(1.05) rotate(180deg);
      }

      @media(max-width:1440px) {
        width: 55px;
        height: 30px;
      }

      @media(max-width:767px) {
        display: none;
      }
    }

    .swiper-button-next {
      left: inherit;
      right: 60px;
      transform: rotate(0);
      &:hover {
        transform: scale(1.05) rotate(0);
      }

      @media(max-width:1440px) {
        right: 40px;
      }
    }

    

    @media (max-width: 767px) {
      width: 100%;

      .swiper-pagination {
        display: flex;
      }
    }
  }

  &__item {
  }
}

.works-item {
  padding: 32px 48px;
  border-radius: 35px;
  background: linear-gradient(180deg, #67AEFF 0%, #B6D8FF 100%);
  color: #fff;
  min-height: 540px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1580px) {
    min-height: 460px;
    padding: 32px;
    border-radius: 24px;
  }

  @media (max-width: 767px) {
    min-height: 360px;
    padding: 20px 24px 0 20px;
  }
  &_bg {
  }

  &__title {
    flex: 1 1 100%;
    font-size: 40px;
    @media (max-width: 1580px) {
      font-size: 32px;
    }

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  &__number {
    font-size: 160px;
    font-weight: 700;
    line-height: 100%;
    position: relative;

    @media (max-width: 1580px) {
      font-size: 120px;
    }
  }

  &__text {
    font-size: 24px;
    line-height: 120%;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
}
</style>
