<template>
  <section class="business-solutions section">
    <div class="business-solutions__container container">
      <div class="business-solutions__body">
        <div class="business-solutions__top">
          <h2 class="business-solutions__item-title subtitle">
            Подходящее решение для вас
          </h2>
        </div>
        <div class="business-solutions__content">
          <div class="business-solutions__item">
            <div class="business-solutions__left">
              <div class="business-solutions__status">Оптимально</div>
              <div class="business-solutions__item-title">Стирка и сушка</div>
            </div>
            <div class="business-solutions__image">
              <img
                src="../../assets/img/business-solutions/item-01.svg"
                alt=""
              />
            </div>
            <div class="business-solutions__text">
              <p>
                Стиральными машинами больше пользуются, если есть сушильная, так
                как не нужно заботиться о месте для сушки белья
              </p>
            </div>
          </div>
          <div class="business-solutions__item">
            <div class="business-solutions__left">
              <div class="business-solutions__item-title">Только стирка</div>
            </div>
            <div class="business-solutions__image">
              <img
                src="../../assets/img/business-solutions/item-02.svg"
                alt=""
              />
            </div>
            <div class="business-solutions__text">
              <p>
                <span>Подойдет для тех, у кого мало места.</span>
                Стирка - базовая потребность, без которой в путешествии гостю
                может быть очень некомфортно
              </p>
            </div>
          </div>
          <div class="business-solutions__item">
            <div class="business-solutions__left">
              <div class="business-solutions__item-title">Только сушка</div>
            </div>
            <div class="business-solutions__image">
              <img
                src="../../assets/img/business-solutions/item-03.svg"
                alt=""
              />
            </div>
            <div class="business-solutions__text">
              <p>
                <span
                  >Подойдет для тех, у кого уже есть стиральная машинка.</span
                >
                Сушильная машинка значительно повышает комфорт гостей, так как
                больше не нужно думать о том, где сушить вещи
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
export default {
  name: "businessSolutions",
  components: {},
  props: {},
  setup() {},
};
</script>

<style scoped lang="scss">
.business-solutions {
  padding: 80px 0;

  &__container {
  }

  &__body {
  }

  &__top {
    margin-bottom: 160px;
    color: #67aeff;

    @media(max-width:1200px) {
        margin-bottom: 60px;
    }

    @media(max-width:767px) {
        margin-bottom: 32px;
    }
  }

  &__title {
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media(max-width:767px) {
        gap: 24px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 80px;
    position: relative;
    border-bottom: 1px solid #67aeff;

    @media (max-width: 1600px) {
      gap: 40px;
    }

    @media(max-width:1200px) {
        flex-direction: column;
        align-items: stretch;
    }
    &:nth-child(2) {
      .business-solutions__image {
        position: relative;
        left: 100px;
     
        img {
          height: 150px;
        }

        @media (max-width: 1600px) {
          left: 43px;
          img {
            height: 110px;
          }
        }

        @media (max-width: 1400px) {
         left: 43px;
        }

        @media (max-width: 1200px) {
           left: -120px;
        }

        @media(max-width:1024px) {
            left: -100px;
        }
      }
    }

    &:nth-child(3) {
      .business-solutions__image {
        position: relative;
        left: -12px;

        @media(max-width:1600px) {
          left: -36px;
        }

        @media (max-width: 1400px) {
           left: -36px;
        }

        @media (max-width: 1200px) {
          left: -120px;
        }

        @media(max-width:1024px) {
            left: -100px;
        }
      }
    }
  }

  &__item-title {
    font-size: 40px;
    color: #67aeff;

    @media (max-width: 1580px) {
      font-size: 36px;
    }

    @media(max-width:767px) {
        font-size: 24px;
    }
  }

  &__left {
  }

  &__status {
    border-radius: 14px;
    background: #b5d8ff;
    top: -40px;
    left: 0;
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    font-size: 24px;
    color: #fff;

    @media (max-width: 1400px) {
      padding: 12px 20px;
      font-size: 20px;
    }

    @media(max-width:1200px) {
        position: static;
        margin-bottom: 10px;
    }

    @media(max-width:767px) {
        padding: 8px 16px;
        font-size: 14px;
        border-radius: 8px;
    }
  }

  &__image {
    position: relative;
    left: -120px;
    align-self: end;
    img {
      height: 138px;
      @media (max-width: 1600px) {
        height: 100px;
      }

      @media(max-width:767px) {
        height: 90px;
      }
    }

    @media (max-width: 1400px) {
      left: -120px;
    }

    @media (max-width: 1200px) {
      left: -120px;
      align-self: start;
    }
    
  }

  &__text {
    font-size: 24px;
    color: #6eb2ff;
    max-width: 470px;
    padding-bottom: 24px;
    span {
      font-weight: 500;
    }

    @media (max-width: 1580px) {
      font-size: 20px;
    }

    @media(max-width:1400px) {
      max-width: 300px;
    }

    @media(max-width:1200px) {
      max-width: 470px;
    }

    @media(max-width:767px) {
        font-size: 16px;
    }
  }
}
</style>
