<template>
  <router-view />
</template>

<style lang="scss">
$blue-default: #2A8BFF;
$blue: #5AA7FF;
$blue-txt: #4198FB;

$maxWidth: 1920;
$maxWidthContainer: 1270;


/*Обнуление*/
*{
	padding: 0;
	margin: 0;
	border: 0;
}
*,*:before,*:after{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
:focus,:active{outline: none;}
a:focus,a:active{outline: none;}

nav,footer,header,aside{display: block;}

html,body{
	height: 100%;
	width: 100%;
	line-height: 1;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}
input,button,textarea{font-family:inherit;}

input::-ms-clear{display: none;}
button{cursor: pointer;}
button::-moz-focus-inner {padding:0;border:0;}
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top;}
a {
    cursor:pointer;
}

h1, h2, h3, h4 {
	font-weight: 400;
	margin: 0;
	line-height: 100%;
	margin-block-start: 0;
    margin-block-end: 0; 
}

._ibg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	object-fit: cover;
}

a {
	color: #fff;
	display:block;
}

button {
	background: transparent;
}

/*--------------------*/


// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
// }

body {
  font-family: 'Lato', "Helvetica Neue", Helvetica, sans-serif;
  * {line-height: 130%;}
}

.container {
  max-width: 1770px;
  padding: 0px 40px;
  margin: 0 auto; 

  @media(max-width:767px) {
    padding: 0px 16px;
  }
}
.button {
  padding: 16px 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 8px;
  color: #3380F6;
  background-color: #fff;
  font-weight: 700;
  transition: all 0.4s ease 0s;
  border: 1px solid #4198FB;
  @media(max-width:1580px) {
    padding: 16px 24px;
    font-size: 18px;
  }

  @media(max-width:1024px) {
    font-size: 14px;

  }

  @media(max-width:492px) {
    border-radius: 8px;
  }

  &:hover {
    opacity: 0.8;
  }

  &_highlight {
    background-color: #5AA7FF;
    color: #fff;
  } 

  &_second {
    color: #67aeff;
    border: 1px solid #67aeff;
  }
}

.section {
  padding: 80px 0px;
}
.container {
}
.title {
  font-size: 85px;
  text-align: left;
  color: $blue-default;
  letter-spacing: -2px;
  font-weight: 500;

  @media(max-width:1580px) {
    font-size: 60px;
  }

  @media(max-width:1300px) {
    font-size: 50px;
  }

  @media(max-width:1024px) {
    font-size: 40px;
  }

  @media(max-width:492px) {
    letter-spacing: -0.5px;
  }
}

.subtitle {
  font-size: 50px;

  @media(max-width:1580px) {
    font-size: 40px;
  }
  
  @media(max-width:492px) {
    font-size: 24px;
  }
}


@font-face {
    font-family: Lato;
    font-display: swap;
    src: url("./assets/fonts/Lato-Bold.woff2") format("woff2"), url("./assets/fonts/Lato-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Lato;
    font-display: swap;
    src: url("./assets/fonts/Lato-medium.woff2") format("woff2"), url("./assets/fonts/Lato-medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Lato;
    font-display: swap;
    src: url("./assets/fonts/Lato-Regular.woff2") format("woff2"), url("./assets/fonts/Lato-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

* {
    padding: 0;
    margin: 0;
    border: 0;
}
*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
:focus,
:active {
    outline: none;
}
a:focus,
a:active {
    outline: none;
}
nav,
footer,
header,
aside {
    display: block;
}
html,
body {
    height: 100%;
    width: 100%;
    text-align: left;
    line-height: 1;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
input,
button,
textarea {
    font-family: inherit;
}
input::-ms-clear {
    display: none;
}
button {
    cursor: pointer;
}
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}
#app {
    height: 100%;
}

a,
a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
ul li {
    list-style: none;
}
img {
    display: block;
    vertical-align: top;
}
a {
    cursor: pointer;
    display: block;
}
h1,
h2,
h3,
h4 {
    font-weight: 400;
    margin: 0;
    line-height: 100%;
    margin-block-start: 0;
    margin-block-end: 0;
}

body {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    line-height: 125%;
}




</style>
