<template>
  <footer class="footer">
    <div class="footer__container container">
      <div class="footer__body">
        <div class="footer__links">
          <a href="#" class="footer__link">
            <img src="../../assets/img/tg.svg" alt="" />
            Telegram
          </a>
          <a href="#" class="footer__link">
            <img src="../../assets/img/wh.svg" alt="" />
            WhatsApp
          </a>
        </div>
        <div class="footer__info">
          <a href="tel:+7(996) 780-78-74" class="footer__label">
            +7(996) 780-78-74
          </a>
          <div class="footer__label">г. Санкт-Петербург, Рентгена 9б</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "intro",
  components: {},
  props: {},
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.footer {
  background: linear-gradient(180deg, #6cb1ff 0.43%, #c7e1ff 121.52%);
  padding: 80px 0;
  margin-top: 40px;
  @media (max-width: 1024px) {
    padding: 40px 0;
    margin-top: 20px;
  }
  &__container {
  }

  &__body {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @media(max-width:492px) {
        gap: 24px;
        flex-direction: column;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media(max-width:492px) {
        gap: 16px;
    }
  }

  &__link {
    font-size: 32px;
    color: #fff;
    transition: all 0.4s ease 0s;
    display: inline-flex;
    align-items: center;
    gap: 24px;
    img {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
    }

    @media(max-width:1400px) {
        font-size: 24px;
    }

    @media(max-width:1024px) {
        font-size: 18px;

        img {
            width: 18px;
            height: 18px;
            flex: 0 0 18px;
            gap: 16px;
        }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media(max-width:492px) {
        gap: 16px;
    }
  }

  &__label {
    font-size: 24px;
    color: #fff;

    @media(max-width:1024px) {
        font-size: 18px;
    }
  }
}
.container {
}
</style>
