<template>
  <header class="header">
    <div class="header__container container">
      <div class="header__body">
        <a href="#" class="header__logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </a>
        <div class="header__content">
          <nav class="header__menu menu">
            <ul class="header__list">
              <li>
                <a href="#" data-goto=".about" class="header__link anchor-link"> Что такое ландромат? </a>
              </li>
              <li>
                <a href="#" data-goto=".benefits" class="header__link anchor-link"> Для чего это нужно? </a>
              </li>
              <li>
                <a href="#" data-goto=".feedback" class="header__link anchor-link"> Рассчитать доход? </a>
              </li>
            </ul>
          </nav>

          <button data-goto=".form" type="button" class="header__button button anchor-link">
            Оставить заявку
          </button>
        </div>
        <button @click="openMenu(this)" type="button" class="header__icon">
          <span></span>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "header",
  components: {},
  props: {},
  setup() {
    const openMenu = () => {
      document.querySelector(".header__icon").classList.toggle("_active");
      document.querySelector(".header__content").classList.toggle("_active");
    };

    return {
      openMenu,

    };
  },
};
</script>

<style scoped lang="scss">
.header {
  padding: 32px 0px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  @media (max-width: 1024px) {
    background: rgba(54, 147, 255, 0.2);
    backdrop-filter: blur(2.5px);
    padding: 16px 0;
  }
  @media (max-width: 1300px) {
    padding: 20px 0px;
  }
  &__container {
  }

  &__body {
    border-radius: 22px;
    background: rgba(54, 147, 255, 0.2);
    backdrop-filter: blur(2.5px);
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    @media (max-width: 1024px) {
      background: transparent;
      padding: 0;
      border-radius: 0;
      backdrop-filter: none;
    }
  }

  &__logo {
    @media (max-width: 1300px) {
      img {
        max-width: 180px;
        width: 100%;
      }
    }

    @media (max-width: 1024px) {
      position: relative;
      z-index: 1;
    }
  }

  &__icon {
    display: none;
    @media (max-width: 1024px) {
      display: block;
      cursor: pointer;
      width: 24px;
      height: 16px;
      flex: 0 0 24px;
      position: relative;
      display: block;
      z-index: 6;
      top: 0;
      right: 0;
      span,
      &:before,
      &:after {
        height: 2px;
        width: 100%;
        left: 0;
        display: block;
        transition: 0.3s;
        position: absolute;
        background: #3380f6;
      }

      &:after,
      &:before {
        content: "";
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }

      span {
        top: 50%;
        transform: scale(1) translate(0px, -50%);
      }

      &._active {
        span {
          transform: scale(0) translate(0px, -50%);
          background-color: #3380f6;
        }

        &:before {
          top: 50%;
          transform: rotate(-45deg) translate(0px, -50%);
        }

        &:after {
          bottom: 50%;
          transform: rotate(45deg) translate(0px, 50%);
        }
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 100px;
    @media (max-width: 1580px) {
      gap: 36px;
    }
    @media (max-width: 1300px) {
      gap: 48px;
    }

    @media (max-width: 1024px) {
      position: fixed;
      top: 0;
      z-index: 5;
      width: 100%;
      height: auto;
      gap: 24px;
      display: flex;
      flex-direction: column;
      align-items: start;
      background-color: #fff;
      left: -100%;
      transition: all 0.4s ease 0s;
      padding: 100px 15px;

      &._active {
        left: 0;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    gap: 100px;
    @media (max-width: 1580px) {
      gap: 48px;
    }

    @media (max-width: 1300px) {
      gap: 32px;
    }

    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 24px;
      align-items: start;
    }
  }

  &__link {
    font-size: 24px;
    color: #3380f6;
    transition: all 0.4s ease 0s;
    &:hover {
      opacity: 0.8;
    }
    @media (max-width: 1580px) {
      font-size: 20px;
    }
    @media (max-width: 1300px) {
      font-size: 16px;
    }
  }

  &__menu {
    display: block;
  }

  &__button {
    border-radius: 16px;
    padding: 16px 18px;

    @media (max-width: 492px) {
      border-radius: 8px;
      padding: 14px 24px;
    }
  }
}
</style>
